import React, { useEffect, useState } from 'react';
import { Rect, Circle, Text, Image as KonvaImage, Group } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { CanvasComponent } from '../hooks/useCanvas';

interface RendererProps {
  component: CanvasComponent;
  onMouseDown: (id: number, e: KonvaEventObject<MouseEvent>) => void;
}

const CanvasComponentRenderer: React.FC<RendererProps> = ({ component, onMouseDown }) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [overlayImage, setOverlayImage] = useState<HTMLImageElement | null>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (component.type === 'image' && component?.src) {
      const img = new Image();
      img.src = component.src;
      img.onload = () => setImage(img);
    }

    // Load the overlay image only if needed for this specific component
    if (component.type === 'image' && component.id === 42) { // Adjust to match the specific config item
      const overlay = new Image();
      overlay.src = '/images/ai_word_definition.png'; // Replace with actual overlay image path
      overlay.onload = () => setOverlayImage(overlay);
    }
  }, [component]);

  if (component.type === 'image') {
    return (
      <Group
        key={component.id}
        x={component.x}
        y={component.y}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={(e) => onMouseDown(component.id, e)}
        draggable // Make the entire group draggable to keep images in sync
      >
        {image && (
          <KonvaImage
            image={image}
            width={component.width}
            height={component.height}
          />
        )}
        {isHovered && overlayImage && (
          <KonvaImage
            image={overlayImage}
            width={320}
            height={85}
            y={-50} // Position overlay relative to the main image
            x={325} // Adjust the x offset as necessary
          />
        )}
      </Group>
    );
  }

  switch (component.type) {
    case 'rectangle':
      return (
        <Rect
          key={component.id}
          x={component.x}
          y={component.y}
          width={100}
          height={50}
          fill="blue"
          draggable
          onMouseDown={(e) => onMouseDown(component.id, e)}
        />
      );
    case 'circle':
      return (
        <Circle
          key={component.id}
          x={component.x}
          y={component.y}
          radius={50}
          fill="green"
          draggable
          onMouseDown={(e) => onMouseDown(component.id, e)}
        />
      );
    case 'text':
      return (
        <Text
          key={component.id}
          x={component.x}
          y={component.y}
          text="Sample Text"
          fontSize={20}
          draggable
          onMouseDown={(e) => onMouseDown(component.id, e)}
        />
      );
    default:
      return null;
  }
};

export default CanvasComponentRenderer;
