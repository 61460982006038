import React, { useEffect, useState } from 'react';
import './Table.css';

interface TableRow {
  month: string;
  category: string;
  price: string;
  comment: string;
  other: string;
}

export const Table: React.FC<{ isEmpty: boolean }> = ({ isEmpty }) => {
  const [rows, setRows] = useState<TableRow[]>([]);

  const handleCellChange = (rowIndex: number, columnKey: keyof Omit<TableRow, 'floorplan'>, newValue: string) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][columnKey] = newValue;
    setRows(updatedRows);
  };

  const addRow = () => {
    const emptyRow: TableRow = {
      month: '',
      category: '',
      price: '',
      comment: '',
      other: '',
    };
    setRows((prevRows) => [...prevRows, emptyRow]);
  };

  useEffect(() => {


    const predefinedRows: TableRow[] = [
      { month: '10.2024', category: 'Groceries',  price: '£421.38', comment: '-', other: ''},
      { month: '10.2024', category: 'Transports', price: '£180.32', comment: '-', other: '' },
      { month: '10.2024', category: 'Restaurants', price: '£234.34', comment: 'Up 64% in the last 2 months', other: '' },
      { month: '10.2024', category: 'Entertainment', price: '£82.00', comment: '-', other: '' },
      { month: '10.2024', category: 'Cash withdrawal', price: '£50.00', comment: '-', other: '' },
      { month: 'TOTAL', category: '', price: '£968.04', comment: '', other: '' },

    ];

    if (isEmpty) {
      setRows([]);
    } else {
      setRows([...predefinedRows]);
    }
  }, [isEmpty]);

  return (
    <div className="table-component">
      <table>
        <thead>
          <tr>
            <th>Month</th>
            <th>Category</th>
            <th>Price</th>
            <th>Comment</th>
            <th>...</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.keys(row).map((columnKey) => (
                <td key={columnKey}>
                  
                   { typeof row[columnKey as keyof TableRow] === 'string' ? (
                      <input
                        type="text"
                        value={row[columnKey as keyof TableRow] as string}
                        onChange={(e) => handleCellChange(rowIndex, columnKey as keyof Omit<TableRow, 'floorplan'>, e.target.value)}
                      />
                    ) : (
                      <span>-</span>
                    )
}
                </td>
              ))}
            </tr>
          ))}
          {isEmpty && (
            <tr className="add-row">
              <td colSpan={6} onClick={addRow}>
                + New
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
