import React from 'react';
import ElementsPopoverItem from './ElementsPopoverItem';
import './ElementsPopover.css';

interface ElementsPopoverSectionProps {
  title: string;
  items: {
    icon: string; // Expecting a string file path for the icon
    title: string;
    color?: string;
    description: string;
    onClick?: () => void;
  }[];
}

const ElementsPopoverSection: React.FC<ElementsPopoverSectionProps> = ({ title, items }) => {
  return (
    <div className="elements-popover-section">
      <h3>{title}</h3>
      <div className="popover-item-group">
        {items.map((item, index) => (
          <ElementsPopoverItem
            key={index}
            // Convert the icon path into an <img> element
            icon={<img src={item.icon} alt={`${item.title} icon`} style={{ width: 24, height: 24, marginRight: 8 }} />}
            title={item.title}
            description={item.description}
            onClick={item.onClick ? item.onClick : () => {}}
            className={item.onClick ? 'clickable' : ''}
            color={item?.color}
          />
        ))}
      </div>
    </div>
  );
};

export default ElementsPopoverSection;
