import React from 'react';
import './ElementsPopover.css';

interface ElementsPopoverItemProps {
  icon: React.ReactNode; // This should be a React node (e.g., an image or JSX)
  title: string;
  description: string;
  color?: string;
  onClick: () => void;
  className: string;
}

const ElementsPopoverItem: React.FC<ElementsPopoverItemProps> = ({ icon, title, description, onClick, color, className }) => {
  return (
    <div className={`elements-popover-item ${className}`} onClick={onClick}>
      <div className="item-info">
        {/* Render the icon directly */}

        <div className="item-details">
          <p className="item-description" style={color ? { backgroundColor: color } : {}}>{description}</p>

        </div>
        <div className="item-header">
          {icon} 
          <p className="item-title">{title}</p>
          </div>
      </div>
    </div>
  );
};


export default ElementsPopoverItem;
