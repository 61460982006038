import React from 'react';
//import ElementsPopoverSearchBar from './ElementsPopoverSearchBar';
import ElementsPopoverSection from './ElementsPopoverSection';
import './ElementsPopover.css';

interface ElementsPopoverProps {
  onAddComponent: (type: string) => void;
}

const ElementsPopover: React.FC<ElementsPopoverProps> = ({onAddComponent}) => {
  const tableItems = [
    {
      icon: "/assets/tableicon.png",
      title: "Blank",
      description: "Add a blank table to start capturing information",
      onClick: () => { onAddComponent("table_blank") },
    },
    {
      icon: "/assets/tableicon.png",
      title: "Spending",
      description: "Capture and categorize spending & budgets",
      onClick: () => { onAddComponent("table") },
      color: "#B1ECEE"
    },
    {
      icon: "/assets/tableicon.png",
      title: "Money pots",
      description: "Track different Money pots and account balances",
      color: "#ACEED2",
    },
    {
      icon: "/assets/tableicon.png",
      title: "Bills",
      description: "Track household bills and who’s paying what",
      color: "#B5B2F5",
    },
    {
      icon: "/assets/tableicon.png",
      title: "Refurb costs",
      description: "Track quotes and quickly add up costs",
      color: "#FFD0D0",
    },
  ];


  return (
    <div className="elements-popover-wrapper">
      <div>
        <ElementsPopoverSection title="Tables" items={tableItems} />
      </div>
      <div>
        <img src='/assets/temp/elements.png' alt='elements' width={610} />
      </div>
    </div>
  );
};

export default ElementsPopover;
